import React, { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import { AuthContext } from '../../context/Auth/AuthContext'
import { Can } from '../../components/Can'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core'

import api from '../../services/api'
import { i18n } from '../../translate/i18n'
import toastError from '../../errors/toastError'
import QueueSelect from '../QueueSelect'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  multFieldLine: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
})

const WhatsAppModal = ({ open, onClose, whatsAppId, whatsAppOfficial }) => {
  const classes = useStyles()
  const initialState = {
    name: '',
    urlApi: '',
    url: '',
    greetingMessage: '',
    farewellMessage: '',
    isDefault: false,
    isOfficial: false,
    number: '',
    phoneNumberId: '',
    wabaId: ''
  }

  const { user } = useContext(AuthContext)

  const [whatsApp, setWhatsApp] = useState(initialState)
  const [selectedQueueIds, setSelectedQueueIds] = useState([])
  const [isOfficial, setIsOfficial] = useState(false)


  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`)  

        setWhatsApp(data)
        setIsOfficial(data?.isOfficial)

        const whatsQueueIds = data.queues?.map((queue) => queue.id)
        setSelectedQueueIds(whatsQueueIds)
      } catch (err) {
        toastError(err)
      }
    }
    fetchSession()
  }, [whatsAppId])

  const handleSaveWhatsApp = async (values) => {

    console.log('values1: ', values)

    const { isOfficial } = values

    if (!isOfficial) {
      values.phoneNumberId = ''
      values.wabaId = ''
      values.number = ''
    }


    const whatsappData = { ...values, queueIds: selectedQueueIds }

    let response = null

    try {
      if (whatsAppId) {
        response = await api.put(`/whatsapp/${whatsAppId}`, whatsappData)
      } else {
        response = await api.post('/whatsapp', whatsappData)
      }

      console.log('response: ', response.data.message)

      if (response && response.data.message === 'wrong_number_start') {
        alert(
          'O numero contido no nome da conexão deve iniciar com o código do país!'
        )
      } else if (response && response.data.message === 'invalid_phone_number') {
        alert(
          'A quantidade de numeros digitados no nome do contato é invalida! Certifique-se de que você digitou o numero correto acompanhado pelo código do país!'
        )
      } else if (response && response.data.message === 'no_phone_number') {
        alert(
          'Para criar/editar uma sessão de Whatsapp é necessário que o numero do Whatsapp acompanhado pelo código do país esteja presente no nome da sessão!'
        )
      } else {
        toast.success(i18n.t('whatsappModal.success'))
        handleClose()
      }
    } catch (err) {
      toastError(err)
    }
  } 

  const handleClose = () => {
    onClose()
    setWhatsApp(initialState)
    setIsOfficial(false)
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t('whatsappModal.title.edit')
            : i18n.t('whatsappModal.title.add')}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values)
              actions.setSubmitting(false)
            }, 400)
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Can
                  role={user.profile}
                  perform="url-remote-session:show"
                  yes={() => (
                    <>
                      <div className={classes.multFieldLine}>
                        <Field
                          as={TextField}
                          label={i18n.t('whatsappModal.form.name')}
                          autoFocus
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                        <FormControlLabel
                          control={
                            <Field
                              as={Switch}
                              color="primary"
                              name="isDefault"
                              checked={values.isDefault}
                            />
                          }
                          label={i18n.t('whatsappModal.form.default')}
                        />

                        {whatsAppOfficial &&
                          <FormControlLabel
                            control={
                              <Field
                                as={Switch}
                                color="primary"
                                name="isOfficial"
                                onClick={() => setIsOfficial(!isOfficial)}
                                checked={values.isOfficial}
                              />
                            }
                            label={'Whatsapp Oficial'}
                          />
                        }


                      </div>

                      {!isOfficial ? <div className={classes.multFieldLine}>
                        <Field
                          as={TextField}
                          label="url API"
                          autoFocus
                          name="urlApi"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                        <Field
                          as={TextField}
                          label="url session"
                          autoFocus
                          name="url"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      </div> :

                        <div className={classes.multFieldLine}>
                          <Field
                            as={TextField}
                            label="Phone number id"
                            autoFocus
                            name="phoneNumberId"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                          />

                          <Field
                            as={TextField}
                            label="WABA ID"
                            autoFocus
                            name="wabaId"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                          />

                          <Field
                            as={TextField}
                            label="Phone number"
                            autoFocus
                            name="number"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                          />
                        </div>



                      }


                    </>
                  )}
                />

                <div>
                  <Field
                    as={TextField}
                    label={i18n.t('queueModal.form.greetingMessage')}
                    type="greetingMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>

                <div>
                  <Field
                    as={TextField}
                    label={i18n.t('whatsappModal.form.farewellMessage')}
                    type="farewellMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="farewellMessage"
                    error={
                      touched.farewellMessage && Boolean(errors.farewellMessage)
                    }
                    helperText={
                      touched.farewellMessage && errors.farewellMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('whatsappModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t('whatsappModal.buttons.okEdit')
                    : i18n.t('whatsappModal.buttons.okAdd')}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

export default React.memo(WhatsAppModal)
