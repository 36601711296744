import axios from "axios";

const apiBroker = axios.create({
	baseURL: process.env.REACT_APP_URL_API_BROKER,
	headers: {
		Authorization: `Bearer ${process.env.REACT_APP_API_BROKER_TOKEN}`
	}
});

export default apiBroker;
