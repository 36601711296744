import React, { useState, createContext } from "react"

const countTicketMsgContext = createContext()


const CountTicketMsgProvider = ({ children }) => {

    const [countTicketMsg, setCountTicketMsg] = useState(0)

    return (
        <countTicketMsgContext.Provider value={{ countTicketMsg, setCountTicketMsg }}>
            {children}
        </countTicketMsgContext.Provider>
    )
}

export { countTicketMsgContext, CountTicketMsgProvider }