


// import React, { useState } from "react";
// import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

// function InlineDateTimePickerDemo(props) {
//   const [selectedDate, handleDateChange] = useState(new Date("2018-01-01T00:00:00.000Z"));

//   return (
//     <>
//       <DateTimePicker
//         variant="inline"
//         label="Basic example"
//         value={selectedDate}
//         onChange={handleDateChange}
//       />

//       <KeyboardDateTimePicker
//         variant="inline"
//         ampm={false}
//         label="With keyboard"
//         value={selectedDate}
//         onChange={handleDateChange}
//         onError={console.log}
//         disablePast
//         format="yyyy/MM/dd HH:mm"
//       />
//     </>
//   );
// }

// export default InlineDateTimePickerDemo;


 
import React, { Fragment, useState, useEffect } from "react";
// import TextField from '@mui/material/TextField';   
import DateFnsUtils from '@date-io/date-fns'; 

import { 
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'; 


import ptBrLocale from "date-fns/locale/pt-BR";


function formatDate(strDate){
  const date = strDate.split(' ')[0].split('/')
  const time = strDate.split(' ')[1] 
  return `${date[2]}-${date[1]}-${date[0]} ${time}` 
}


const ResponsiveTimePickers = (props) => {
  
  const [value, setValue] = useState(new Date(new Date(formatDate(props.schedulingDate))));

  // props.func(value);

  useEffect(()=>{
    
    props.func(value);

  }, [value, props])

  return (

    <Fragment>

    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
          <TimePicker
              variant="outline"
              label={props.title} 
              value={value}
              ampm={false}    
              onChange={(newValue) => {
                setValue(newValue);
              }}
              // Ativar se necessario
              // renderInput={(params) => <TextField {...params} />} 
                             
          />
      </MuiPickersUtilsProvider>    

    </Fragment>
 
  );
}

export default ResponsiveTimePickers
