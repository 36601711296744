import React, { useState, useEffect } from "react"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'




export default function MaxWidthDialog(props) {
    const [open, setOpen] = useState(false)
    const [fullWidth,] = useState(true)
    const [currency, setCurrency] = useState(props.reportOption)
    const [textOption, setTextOption] = useState('')


    useEffect(() => {

        // props.func(currency)

        if(currency === '2' || currency === '3'){
            setTextOption('Retorna apenas tickets com status: fechado')
        }
        else{
            setTextOption('Retorna todos os tickets com status: aberto, fechado, pendente')
        }

    }, [currency, props])


    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {

        props.func(currency)

        setOpen(false)
    }

    const handleMaxWidthChange = (event) => {

        setCurrency(event.target.value)


    }



    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                CSV ALL
            </Button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={'sm'}
                open={open}
                onClose={null}
            >
                <DialogTitle>Relatórios</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Escolha uma opção do tipo de relatório abaixo
                    </DialogContentText>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >
                        <FormControl sx={{ mt: 2, minWidth: 420 }}>

                            <InputLabel htmlFor="opcoes">opcoes</InputLabel>

                            <Select
                                autoFocus
                                value={currency}
                                onChange={handleMaxWidthChange}
                                label="opcoes"
                                inputProps={{
                                    name: 'opcoes',
                                    id: 'opcoes',
                                }}
                            >


                                {props.currencies.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>   {option.label}  </MenuItem>
                                ))}


                            </Select>

                        </FormControl>

                        {/* <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            /> */}
                    </Box>
                </DialogContent>
                <div style={{ display: 'flex', justifyContent: "center" }}>{textOption}</div>

                <div style={{ display: 'flex', justifyContent: "right" }}>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    </DialogActions>
                    <DialogActions>
                        <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </div>

            </Dialog>
        </React.Fragment>
    )
}
