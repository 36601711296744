import React, { useState, useEffect, useRef, useContext } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import openSocket from 'socket.io-client'


import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { i18n } from "../../translate/i18n"
import QueueSelect from '../QueueSelect'
import { AuthContext } from '../../context/Auth/AuthContext'

import api from "../../services/api"
import toastError from "../../errors/toastError"

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}))

const PositionSchema = Yup.object().shape({
  name: Yup.string() 
    .required("Required"), 
})

const PositionModal = ({
  open,
  onClose,
  positionId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles()
  const isMounted = useRef(true)

  const initialState = {
    name: "", 
  }

  const [position, setPosition] = useState(initialState)
  // const [selectedQueueIds, setSelectedQueueIds] = useState([])
  const { setting } = useContext(AuthContext)
  const [settings, setSettings] = useState(setting)
 

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setSettings(setting)
  }, [setting])


  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    socket.on('settings', (data) => {
      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    const fetchPosition = async () => {
      if (initialValues) {
        setPosition((prevState) => {
          return { ...prevState, ...initialValues }
        })
      }

      if (!positionId) return

      try {
        const { data } = await api.get(`/positions/${positionId}`)
        if (isMounted.current) {
          setPosition(data)

          // if (data?.queues) {
          //   console.log('data.queues: ', data.queues)
          //   const quickQueueIds = data.queues?.map((queue) => queue.id)
          //   setSelectedQueueIds(quickQueueIds)
          // }
        }
      } catch (err) {
        toastError(err)
      }
    }

    fetchPosition()
  }, [positionId, open, initialValues])

  const handleClose = () => {
    onClose()
    setPosition(initialState)
  }

  const handleSavePosition = async (values) => {
    try {
  
      if (positionId) {
        await api.put(`/positions/${positionId}`, values)
        handleClose()
        toast.success("Cargo editado com sucesso")
      } else {
        const { data } = await api.post("/positions", values)
        if (onSave) {
          onSave(data)
        }
        handleClose()
        toast.success("Cargo salvo com sucesso")
      }
     
    } catch (err) {
      toastError(err)
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {positionId
            ? `Editar Cargo`
            : `Adicionar Cargo`}
        </DialogTitle>
        <Formik
          initialValues={position}
          enableReinitialize={true}
          validationSchema={PositionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSavePosition(values)
              actions.setSubmitting(false)
            }, 400)
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={'Nome'}
                    name="name"
                    autoFocus
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div> 
                {/* <div>
                  {
                    ((settings && getSettingValue('quickAnswerByQueue') === 'enabled')) && (
                      <QueueSelect
                        selectedQueueIds={selectedQueueIds}
                        onChange={(selectedIds) => {  
                          return setSelectedQueueIds(selectedIds)
                        }}
                        _queues={user.queues}
                      />
                    )
                  }
                </div> */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {positionId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

export default PositionModal
