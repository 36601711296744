import React, { useState, useContext, useEffect, useReducer } from "react"
import openSocket from "socket.io-client"

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
  CheckCircle,
} from "@material-ui/core"
import { Edit, DeleteOutline } from "@material-ui/icons"
import SearchIcon from "@material-ui/icons/Search"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import Title from "../../components/Title"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import QuickAnswersModal from "../../components/QuickAnswersModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import { toast } from "react-toastify"
import toastError from "../../errors/toastError"
import { AuthContext } from '../../context/Auth/AuthContext'
import StatusChatEndModal from "../../components/StatusChatEndModal"
import Switch from '@mui/material/Switch'

const reducer = (state, action) => {
  if (action.type === "LOAD_STATUS_CHAT_END") {
    const statusChatEnds = action.payload
    const newQuickAnswers = []

    statusChatEnds.forEach((statusChatEnd) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === statusChatEnd.id)
      if (quickAnswerIndex !== -1) {
        state[quickAnswerIndex] = statusChatEnd
      } else {
        newQuickAnswers.push(statusChatEnd)
      }
    })

    return [...state, ...newQuickAnswers]
  }

  if (action.type === "UPDATE_STATUS_CHAT_END") {
    const statusChatEnd = action.payload
    const quickAnswerIndex = state.findIndex((q) => q.id === statusChatEnd.id)

    if (quickAnswerIndex !== -1) {
      state[quickAnswerIndex] = statusChatEnd
      return [...state]
    } else {
      return [statusChatEnd, ...state]
    }
  }

  if (action.type === "DELETE_STATUS_CHAT_END") {
    const quickAnswerId = action.payload

    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId)
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1)
    }
    return [...state]
  }

  if (action.type === "RESET") {
    return []
  }
}

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}))

const StatusChatEnd = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParam, setSearchParam] = useState("")
  const [statusChatEnds, dispatch] = useReducer(reducer, [])
  const [selectedStatusChatEnd, setSelectedStatusChatEnd] = useState(null)
  const [statusChatEndModalOpen, setStatusChatEndsModalOpen] = useState(false)
  const [deletingStatusChatEnds, setDeletingStatusChatEnds] = useState(null)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  // const { user, } = useContext(AuthContext)
  const [checked, setChecked] = useState(new Array(statusChatEnds.length).fill(false))

  useEffect(() => {
    dispatch({ type: "RESET" })
    setPageNumber(1)
  }, [searchParam])

  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/statusChatEnd", {
            params: { searchParam, pageNumber },
          })
         
          setChecked(data?.statusChatEnd?.map(s => s.isDefault ? true : false))

          dispatch({ type: "LOAD_STATUS_CHAT_END", payload: data.statusChatEnd })
          setHasMore(data.hasMore)
          setLoading(false)
        } catch (err) {
          toastError(err)
        }
      }
      fetchQuickAnswers()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchParam, pageNumber])

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    socket.on("statusChatEnd", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_STATUS_CHAT_END", payload: data.statusChatEnd })
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_STATUS_CHAT_END",
          payload: +data.statusChatEndId,
        })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase())
  }

  const handleOpenQuickAnswersModal = () => {
    setSelectedStatusChatEnd(null)
    setStatusChatEndsModalOpen(true)
  }

  const handleCloseQuickAnswersModal = () => {
    setSelectedStatusChatEnd(null)
    setStatusChatEndsModalOpen(false)
  }

  const handleEditStatusChatEnd = (statusChatEnd) => {
    setSelectedStatusChatEnd(statusChatEnd)
    setStatusChatEndsModalOpen(true)
  }

  const handleDeleteStatusChatEnd = async (statusChatEndId) => {
    try {
      await api.delete(`/statusChatEnd/${statusChatEndId}`)
      toast.success("Status de encerramento excluido com sucesso")
    } catch (err) {
      toastError(err)
    }
    setDeletingStatusChatEnds(null)
    setSearchParam("")
    setPageNumber(1)
  }

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1)
  }

  const handleChange = async (event, statusChatEnd, index) => {
 
    const newChecked = new Array(statusChatEnds.length).fill(false)
    newChecked[index] = event.target.checked
    setChecked(newChecked)

    try {
      const { id } = statusChatEnd
      await api.put(`/statusChatEnd/${id}`, { isDefault: event.target.checked }) 
      toast.success("Status de encerramento padrão salvo com sucesso")

    } catch (error) {
      toast.success("Erro: ", error)

    }
  }


  const handleScroll = (e) => {
    if (!hasMore || loading) return
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore()
    }
  }

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingStatusChatEnds &&
          `Você tem certeza que quer excluir esta Status de encerramento: ${deletingStatusChatEnds.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteStatusChatEnd(deletingStatusChatEnds.id)}
      >
        {i18n.t("quickAnswers.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <StatusChatEndModal
        open={statusChatEndModalOpen}
        onClose={handleCloseQuickAnswersModal}
        aria-labelledby="form-dialog-title"
        statusChatEndId={selectedStatusChatEnd && selectedStatusChatEnd.id}
      ></StatusChatEndModal>
      <MainHeader>
        <Title>{"Status de encerramento"}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("quickAnswers.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenQuickAnswersModal}
          >
            {"Adicionar Status"}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {"Status de encerramento"}
              </TableCell>
              <TableCell align="center">
                {"Mensagem de despedida"}
              </TableCell>
              <TableCell align="center">
                {"Padrão"}
              </TableCell>
              <TableCell align="center">
                {i18n.t("quickAnswers.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {statusChatEnds.map((statusChatEnd, index) => {

                return (
                  <TableRow key={statusChatEnd.id}>
                    <TableCell align="center">{statusChatEnd.name}</TableCell>
                    <TableCell align="center">{statusChatEnd.farewellMessage}</TableCell>

                    <TableCell align="center">
                      <Switch
                        checked={checked[index]}
                        onChange={(event) => handleChange(event, statusChatEnd, index)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleEditStatusChatEnd(statusChatEnd)}
                      >
                        <Edit />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmModalOpen(true)
                          setDeletingStatusChatEnds(statusChatEnd)
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  )
}

export default StatusChatEnd
