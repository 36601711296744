import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
 



export default function MaxWidthDialog(props)  {
  const [open, setOpen] = useState(false);
  const [fullWidth, ] = useState(true);
  const [currency, setCurrency] = useState(props.reportOption);


useEffect(()=>{
  
    props.func(currency);  
     
},[currency, props]) 


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => { 

    setCurrency( event.target.value)

    
  };

 

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
       MENU
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Relatórios</DialogTitle>
       
        <DialogContent>
          <DialogContentText>
           Escolha uma opção de relatório abaixo
          </DialogContentText>
         
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 420 }}>

              <InputLabel htmlFor="opcoes">opcoes</InputLabel>
            
              <Select
                autoFocus
                value={currency}
                onChange={handleMaxWidthChange}
                label="opcoes"
                inputProps={{
                  name: 'opcoes',
                  id: 'opcoes',
                }}
              >
                  
                 
              {props.currencies.map((option, index) => (   
                <MenuItem key={index} value={option.value}>   {option.label}  </MenuItem>
              ))} 

                {/* <MenuItem value={false}>false</MenuItem>
                <MenuItem value="1">xs</MenuItem>
                <MenuItem value="2">sm</MenuItem>
                <MenuItem value="3">md</MenuItem>
                <MenuItem value="4">lg</MenuItem>
                <MenuItem value="5">xl</MenuItem> */}

              </Select>

            </FormControl>

            {/* <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            /> */}
          </Box>
        </DialogContent>
       
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
     
      </Dialog>
    </React.Fragment>
  );
}
