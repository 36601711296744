
import React, { useState, useEffect, useRef, } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import SelectField from "../Report/SelectField"

import TextField from '@mui/material/TextField'



const ModalTemplate = ({ templates, modal_header, func }) => {

    templates = [{}, ...templates]

    // console.log('TEMPLATES: ', templates)

    const [open, setOpen] = useState(true)
    const [scroll, /*setScroll*/] = useState('body')
    const [templateId, setTemplateId] = useState(null)
    const [templateComponents, setTemplateComponents] = useState(null)
    const [language, setLanguage] = useState(null)
    const [params, setParams] = useState([])

    const handleCancel = (event, reason) => {

        if (reason && reason === "backdropClick")
            return

        setOpen(false)
    }

    const handleChatEnd = () => {

        console.log('PARAMS TO SEND TO MESSAGE INPUT: ', params)
        console.log('templateComponents: ', templateComponents)

        if (params && params.length === 1) {

            const bodyObject = templateComponents.find(obj => obj?.type === 'BODY')

            if (bodyObject) {
                const { text } = bodyObject   
                func([...params, {
                    "type": "BODY",
                    "text": text,
                    "language": "pt_BR",
                }])
            }
        }
        else {
            func(params)
        }

        setOpen(false)
    }

    const descriptionElementRef = useRef(null)
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])


    // Get from child 1
    const changedTextFieldSelect = (data) => {
        setTemplateId(data)
    }

    useEffect(() => {

        const index = templates.findIndex(t => t.id === templateId)
        setParams([])
        if (index !== -1) {

            const { components, language, name } = templates[index]

            setParams((params) => [...params, { 'template_name': name }])

            const buttons = components.find(c => c.type === 'BUTTONS')

            if (buttons) {
                handleButtons(buttons?.buttons)
            }

            setTemplateComponents(components)
            setLanguage(language)
        }
        else {
            setTemplateComponents(null)
            setLanguage(null)
        }

    }, [templateId])

    const handleButtons = (buttons) => {

        let buttonsParams = {
            type: 'BUTTONS',
            parameters: []
        }

        if (buttons && buttons.length > 0) {
            for (let i in buttons) {
                const { text, type: sub_type } = buttons[i]
                buttonsParams.parameters.push({ sub_type, text, index: i, })
            }
        }

        setParams((params) => [...params, buttonsParams])

    }

    const handleTextChange = (value, index, type, text, language,) => {

        if (!params) return 

        setParams((params) => {

            const _index = params.findIndex(({ type }) => type === 'BODY')

            if (_index !== -1) {

                const indexParameter = params[_index].parameters.findIndex((param) => param.index === index)

                if (indexParameter !== -1) {
                    params[_index].parameters[indexParameter] = { ...params[_index].parameters[indexParameter], type: 'text', text: value, index }
                }
                else {
                    params[_index].parameters = [...params[_index].parameters, { type: 'text', text: value, index }]
                }

                return params

            }
            return [...params, { type, text, language, parameters: [{ type: 'text', text: value, index }] }]

        })
    }

    useEffect(() => {
        console.log('---------> PARAMS: ', params)
        console.log('---------> templateComponents: ', templateComponents)

    }, [params, templateComponents])

    const dinamicTextField = (replicateItems, func, type, text, language) => {

        let textFields = Array.from({ length: replicateItems }, (_, index) => index)

        return textFields.map((t) => {
            return <TextField
                key={t}
                label={`{{${t + 1}}}`}
                variant="outlined"
                style={{ margin: '4px', }} // Adjust the height as needed 
                onChange={(e) => func(e.target.value, (t + 1), type, text, language)}
            />
        })
    }


    return (

        <Dialog
            open={open}
            onClose={handleCancel}
            // fullWidth={true}
            // maxWidth={true}    
            disableEscapeKeyDown

            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >

            <DialogTitle id="scroll-dialog-title">{modal_header}</DialogTitle>
            <DialogContent dividers={scroll === 'body'}>

                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >

                </DialogContentText>

                <>

                    <SelectField func={changedTextFieldSelect}
                        emptyField={false}
                        textBoxFieldSelected={'1'}
                        header={'Selecione um template'}
                        currencies={templates.map((template,) => {
                            const { name, id } = template
                            return { 'value': id, 'label': name }
                        })} />

                    {templateComponents &&
                        templateComponents.map((components,) => {
                            const { type, format, text, buttons } = components

                            let body_params = 0

                            if (type === 'BODY') {
                                body_params = text.match(/{{\d+}}/g)
                            }

                            const titleCss = {
                                margin: 0,
                                fontSize: 12
                            }
                            const valueCss = { margin: 0, padding: 0, 'marginBottom': '15px', fontSize: 12 }
                            const valueCssButton = { margin: 0, padding: 0, fontSize: 11 }

                            return <div key={text}>
                                {type && <strong style={titleCss}>{type}</strong>}
                                {format && format !== 'TEXT' && <p style={valueCss}>TYPE {format}</p>}
                                {text &&
                                    <div style={{ margin: 0, padding: 0, 'marginBottom': '15px' }}>
                                        <p style={{ margin: 0, padding: 0, fontSize: 12 }}>{text}</p>
                                        {type && (type === 'BODY') && body_params && dinamicTextField(body_params.length, handleTextChange, type, text, language)}
                                    </div>}
                                {buttons && <div>{buttons.map((b) => {
                                    const { type, text, url } = b
                                    return <div style={{ margin: 0, padding: 0, 'marginBottom': '10px' }}>
                                        {type && <p style={valueCssButton}>TYPE {type}</p>}
                                        {text && <p style={valueCssButton}>{text}</p>}
                                        {url && <p style={valueCssButton}>{url}</p>}
                                    </div>
                                })} </div>}

                            </div>
                        })
                    }



                </>



            </DialogContent>


            <DialogActions>
                <div style={{ marginRight: '50px' }}>
                    <Button onClick={handleCancel}>Cancelar</Button>
                </div>
                <Button onClick={handleChatEnd}>Ok</Button>
            </DialogActions>
        </Dialog >

    )
}

export default ModalTemplate