import { Box } from '@material-ui/core';
import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { PieChart as RechartsPieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import Title from './Title';

const generateDataExample = (amount) => {
  const arr = []
  for (let i = 1; i <= amount; i++) {
    arr.push({
      "id": i,
      "name": `Exemplo ${i}`,
      "count": Math.floor(Math.random() * 10 + 2)
    })
  }

  return arr
}

const dataExample = generateDataExample(20)

const COLORS = [
  '#0088FE', // Azul escuro
  '#00C49F', // Verde menta
  '#FFBB28', // Laranja escuro
  '#FF8042', // Vermelho escuro
  '#9D38BD', // Roxo escuro
  '#FFD166', // Laranja claro
  '#331F00', // Marrom escuro
  '#C0FFC0', // Verde Claro
  '#C4E538', // Verde-amarelo vibrante
  '#A2A2A2', // Cinza claro
  '#FFF700', // Amarelo Canário
  '#FF69B4', // Rosa Flamingo
  '#87CEEB', // Azul Celeste
  '#228B22', // Verde Esmeralda
  '#9B59B6', // Roxo Ametista
  '#FF9933', // Laranja Tangerina
  '#FF7F50', // Coral Vivo
  '#00CED1', // Verde Água
  '#000080', // Azul Marinho
  '#FFDB58', // Amarelo Mostarda
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, count }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.80;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {count}
    </text>
  );
};

/**
 * @param data array de objetos no formato 
 * {
    "id": number | string,
    "name": string,
    "count": number
 * }
 */
const PieChart = ({ data = dataExample }) => {
  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      display="flex"
      sx={{ overflowY: "scroll" }}
    >
      <Box width="100%" height="100%" position="sticky" top="0" zIndex={1000}>
        <Box sx={{ position: "absolute" }}>
          <Title>Tickets encerramento</Title>
        </Box>
        <ResponsiveContainer width="100%" height="100%">
          <RechartsPieChart width={400} height={400}>
            <Pie
              data={data}
              cx="25%"
              cy="60%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="count"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${entry.id}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </RechartsPieChart>
        </ResponsiveContainer>
      </Box>
      <Box
        component="ul"
        sx={{
          position: "absolute",
          top: 0, right: 0,
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          maxWidth: "60%",
          minWidth: "50%",
          zIndex: 0,
        }}>
        {data.map((entry, index) => {
          return (
            <Box
              component="li"
              key={entry.id}
              sx={{
                display: "flex", gap: "2px",
                color: COLORS[index % COLORS.length],
                alignItems: "center"
              }}>
              <FiberManualRecordIcon fill={COLORS[index % COLORS.length]} />
              <text style={{ color: 'black' }}>{entry.name}</text>
            </Box>
          )
        })}
      </Box>
    </Box >
  );

}

export default PieChart