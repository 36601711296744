import React, { useState, useEffect, useContext } from 'react'
import openSocket from 'socket.io-client'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select'
import { toast } from 'react-toastify'

import api from '../../services/api'
import { i18n } from '../../translate/i18n.js'
import toastError from '../../errors/toastError'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'


//--------
import { AuthContext } from '../../context/Auth/AuthContext'

import { Can } from '../../components/Can'
import { boolean } from 'yup'

// import Button from "@material-ui/core/Button";

const IntegerInput = ({ value, onChange }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value
    // Only allow digits 0-9
    if (/^\d{0,3}$/.test(inputValue)) {
      onChange(inputValue)
    }
  }

  return (
    <TextField
      type="text"
      variant="outlined"
      value={value}
      onChange={handleChange}
      style={{ marginRight: '10px' }}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },

  settingOption: {
    marginLeft: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
}))

const Settings = () => {
  const classes = useStyles()

  //--------
  const { user } = useContext(AuthContext)

  const [settings, setSettings] = useState([])


  const [number1, setNumber1] = useState('')
  const [number2, setNumber2] = useState('')

  const handleNumber1Change = (value) => {
    setNumber1(value)
  }

  const handleNumber2Change = (value) => {
    setNumber2(value)
  }

  const handleGetValues = () => {
    let e = {
      target: {
        value: 'enabled', name: 'remoteTicketSendControll', obj: (number1.trim().length > 0 && number2.trim().length > 0) ? { seconds1: number1, seconds2: number2 } : null
      }
    }

    handleChangeSetting(e)
  }


  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get('/settings')
        console.log('data.settings: ', data.settings)
        setSettings(data.settings)

        if (data?.settings) {
          let { obj } = data.settings.find((s) => s.key === 'remoteTicketSendControll')

          if (!obj) return

          obj = JSON.parse(obj)
          console.log('SETTING obj: ', obj)

          setNumber1(obj.seconds1)
          setNumber2(obj.seconds2)
        }

      } catch (err) {
        toastError(err)
      }
    }
    fetchSession()
  }, [])


  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    socket.on('settings', (data) => {
      console.log('settings updated ----------------------------')

      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])


  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value
    const settingKey = e.target.name

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
        // obj: e.target?.obj ? e.target.obj : null
      })

      if (settingKey === 'farewellMessageByQueue' &&
        selectedValue === 'enabled' &&
        getSettingValue('farewellMessageByStatusChatEnd') === 'enabled') {
        await api.put(`/settings/farewellMessageByStatusChatEnd`, {
          value: 'disabled',
        })
      }

      if (settingKey === 'farewellMessageByStatusChatEnd' &&
        selectedValue === 'enabled' &&
        getSettingValue('farewellMessageByQueue') === 'enabled') {
        await api.put(`/settings/farewellMessageByQueue`, {
          value: 'disabled',
        })
      }


      toast.success(i18n.t('settings.success'))
    } catch (err) {
      toastError(err)
    }
  }

  const getSettingValue = (key, _obj = false) => {
    const { value, obj } = settings.find((s) => s.key === key)

    if (_obj)
      return obj

    return value
  }

  const isSaveDisabled = (settings &&
    settings.length > 0 &&
    getSettingValue('remoteTicketSendControll') === 'disabled')


  return (
    <Can
      role={user.profile}
      perform="settings-view:show"
      yes={() => (
        <div>
          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Typography variant="body2" gutterBottom>
                {i18n.t('settings.title')}
              </Typography>

              <Paper className={classes.paper}>
                <Typography variant="body1">
                  {i18n.t('settings.settings.userCreation.name')}
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="userCreation-setting"
                  name="userCreation"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('userCreation')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">
                    {i18n.t('settings.settings.userCreation.options.enabled')}
                  </option>
                  <option value="disabled">
                    {i18n.t('settings.settings.userCreation.options.disabled')}
                  </option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">Editar ura</Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="editURA-setting"
                  name="editURA"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('editURA')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">Editar fila</Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="editQueue-setting"
                  name="editQueue"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('editQueue')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Contato conversar com whatsapps distintos no omnihit
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="oneContactChatWithManyWhats-setting"
                  name="oneContactChatWithManyWhats"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('oneContactChatWithManyWhats')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Whatsapp Cloud API
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="whatsaAppCloudApi-setting"
                  name="whatsaAppCloudApi"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('whatsaAppCloudApi')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Escopo de transferência de usuario por filas atribuidas a conexão e usuarios atribuido a filas
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="queueTransferByWhatsappScope-setting"
                  name="queueTransferByWhatsappScope"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('queueTransferByWhatsappScope')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Modulo campanha
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="hasCampaign-setting"
                  name="hasCampaign"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('hasCampaign')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Respostas rápidas por fila
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="quickAnswerByQueue-setting"
                  name="quickAnswerByQueue"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('quickAnswerByQueue')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Mensagem de encerramento por fila
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="farewellMessageByQueue-setting"
                  name="farewellMessageByQueue"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('farewellMessageByQueue')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>


          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Mensagem de encerramento por status de fechamento
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="farewellMessageByStatusChatEnd-setting"
                  name="farewellMessageByStatusChatEnd"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('farewellMessageByStatusChatEnd')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Exibir contatos por fila
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="contactByqueues-setting"
                  name="contactByqueues"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('contactByqueues')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Controle de envio de mensagem de ticket remoto por numero
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="remoteTicketSendControll-setting"
                  name="remoteTicketSendControll"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('remoteTicketSendControll')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
              {/* <Paper>
                <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h3>Tempo aleatorio em segundos</h3>

                  <div style={{ marginBottom: '20px', display: 'flex' }}>
                    <IntegerInput title="Number 1" value={number1} onChange={handleNumber1Change} />
                    <IntegerInput title="Number 2" value={number2} onChange={handleNumber2Change} />
                    <Button variant="contained" color="primary" onClick={handleGetValues} disabled={
                      (isSaveDisabled) ? true : false
                    }>
                      Save
                    </Button>
                  </div>

                </div>
              </Paper> */}

            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Noficar quando entrar novo ticket na fila
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="notificationTransferQueue-setting"
                  name="notificationTransferQueue"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('notificationTransferQueue')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>
          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Bloquear mídias de Audio e Video
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="blockAudioVideoMedia-setting"
                  name="blockAudioVideoMedia"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('blockAudioVideoMedia')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>

          <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  Mostrar tempo de espera dos tickets aguardando
                </Typography>

                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="waitingTimeTickets-setting"
                  name="waitingTimeTickets"
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue('waitingTimeTickets')
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="enabled">Ativado</option>
                  <option value="disabled">Desativado</option>
                </Select>
              </Paper>
            </Container>
          </div>        
        </div>
      )}
    />
  )
}



export default Settings
