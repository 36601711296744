import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"

import { toast } from "react-toastify"
import openSocket from "socket.io-client"
import clsx from "clsx"

import { Paper, makeStyles } from "@material-ui/core"

import ContactDrawer from "../ContactDrawer"
import MessageInput from "../MessageInput/"
import TicketHeader from "../TicketHeader"
import TicketInfo from "../TicketInfo"
import TicketActionButtons from "../TicketActionButtons"
import MessagesList from "../MessagesList"
import api from "../../services/api"
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext"
import toastError from "../../errors/toastError"
import { CountTicketMsgProvider } from "../../context/CountTicketMsgProvider/CountTicketMsgProvider"

const drawerWidth = 320

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}))

const Ticket = () => {
  const { ticketId } = useParams()
  const history = useHistory()
  const classes = useStyles()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [contact, setContact] = useState({})
  const [ticket, setTicket] = useState({})

  const [statusChatEnd, setStatusChatEnd] = useState({})
  const [defaultStatusChatEnd, setDefaultStatusChatEnd] = useState('')

  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {

          // maria julia

          const { data } = await api.get("/tickets/" + ticketId)

          // setContact(data.contact);
          // setTicket(data); 

          setContact(data.contact.contact)
          setTicket(data.contact)

          setStatusChatEnd(data.statusChatEnd)

          setLoading(false)
        } catch (err) {
          setLoading(false)
          toastError(err)
        }
      }
      fetchTicket()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [ticketId, history])

  useEffect(() => {

    (async () => {
      try {

        const { data } = await api.get("/statusChatEnd/true")

        setDefaultStatusChatEnd(data?.name?.trim())

      } catch (err) {
        toastError(err)
      }
    })()
  }, [])

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    socket.on("connect", () => socket.emit("joinChatBox", ticketId))

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket)
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.")
        history.push("/tickets")
      }
    })

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact }
          }
          return prevState
        })
      }
    })

    socket.on("remoteTickesControllIdleOpen", (data) => {
      if (data.action === "update") {
        let url_ticketId
        try {

          let url_split = window.location.href.split('tickets')

          url_ticketId = url_split[url_split.length - 1].match(/\d+/)[0]

          if (url_ticketId && +url_ticketId === data.ticketId) {
            history.push("/tickets")
          }

        } catch (error) {
          console.log('error on try do the send seen: ', error)
        }
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [ticketId, history])

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} statusChatEnd={statusChatEnd} defaultStatusChatEnd={defaultStatusChatEnd} />
          </div>
        </TicketHeader>
        <ReplyMessageProvider>

          <CountTicketMsgProvider>

            <MessagesList
              ticketId={ticketId}
              isGroup={ticket.isGroup}
            />

            <MessageInput
              ticketStatus={ticket.status}
              ticketLastMessage={ticket?.lastMessage}
              ticketIsRemote={ticket?.isRemote} />

          </CountTicketMsgProvider>

        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />
    </div>
  )
}

export default Ticket
