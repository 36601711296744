 
import React, { useState, useRef} from "react";  
import { CSVLink } from "react-csv";
import Button from '@mui/material/Button'; 



const ExportCSV = (props) => {  
  
    const csvLink = useRef()   

    let headerFormat = JSON.parse(JSON.stringify(props.columns).split('"field":').join('"key":'))
    headerFormat =  JSON.parse(JSON.stringify(headerFormat).split('"title":').join('"label":')) 
    
    const [header, /*setHeader*/] = useState(headerFormat)
    const [dataRow, /*setDataRow*/] = useState(props.data)

    //useEffect(()=>{  
     
    //     setHeader(headerFormat)  
    //     setDataRow(props.data)
    // },[])

    const getTransactionData = async () => { 

        csvLink.current.link.click()
        
      }
 
    return (   
        <div> 
            <Button onClick={getTransactionData}>CSV EXPORT</Button>
            <CSVLink
            headers={header}
            data={dataRow} 
            filename=  {props.user+'_'+props.clientContactNumber+'.csv'} 
            target={'_blank'}
            className='hidden'
            ref={csvLink}  
            />
        </div>  
    );
}

export default ExportCSV