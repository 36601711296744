import React, { useState, createContext } from "react"

const ticketsContext = createContext()


const TicketsProvider = ({ children }) => {

    const [tickets, setTickets] = useState(0)

    return (
        <ticketsContext.Provider value={{ tickets, setTickets }}>
            {children}
        </ticketsContext.Provider>
    )
}

export { ticketsContext, TicketsProvider }