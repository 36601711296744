import React, { useState, useEffect, useContext } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton
} from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'

import { makeStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"

import { i18n } from "../../translate/i18n"

import api from "../../services/api"
import toastError from "../../errors/toastError"
import QueueSelect from "../QueueSelect"
import { AuthContext } from "../../context/Auth/AuthContext"
import { Can } from "../Can"
import Switch from '@mui/material/Switch'

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}))

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),

	email: Yup.string().min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),

	// email: Yup.string().email("Invalid email").required("Required"),
})

const UserModal = ({ open, onClose, userId, }) => {
	const classes = useStyles()

	const initialState = {
		name: "",
		email: "",
		password: "", 
		profile: "user",
	}

	const { user: loggedInUser } = useContext(AuthContext)

	const [user, setUser] = useState(initialState)
	const [selectedQueueIds, setSelectedQueueIds] = useState([])
	const [showPassword, setShowPassword] = useState(false)
	const [positions, setPositions] = useState([])
	const [selectedPosition, setSelectedPosition] = useState('')
	const [checked, setChecked] = useState(false)

	useEffect(() => {
		const fetchUser = async () => {
			setSelectedPosition('')
			if (!userId) return
			try {
				const { data } = await api.get(`/users/${userId}`)
				setUser(prevState => {
					return { ...prevState, ...data }
				})
				const userQueueIds = data.queues?.map(queue => queue.id)
				setSelectedQueueIds(userQueueIds)

				if (data?.positionId)
					setSelectedPosition(data.positionId)
				else
					setSelectedPosition('')

				
				if(data.transferToOtherQueues) setChecked(data.transferToOtherQueues);
			} catch (err) {
				toastError(err)
			}
		}

		fetchUser()
	}, [userId, open])

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const { data } = await api.get(`/positions`)
				setPositions(data.positions)
			} catch (err) {
				toastError(err)
			}
		}

		fetchUser()
	}, [userId, open])

	const handleClose = () => {
		onClose()
		setUser(initialState)
		setChecked(false);
	}

	const handleChange = (event) => {
		setChecked(event.target.checked)
	}

	const handleSaveUser = async values => {
		const userData = { ...values, queueIds: selectedQueueIds, positionId: selectedPosition, transferToOtherQueues: checked}
		try {
			if (userId) {

				const user = await api.put(`/users/${userId}`, userData)

				console.log('USER: ', user.data)


				if (user && user.data.userQueuesAttendance.length > 0) {

					const userQueueInAttendance = user.data.userQueuesAttendance.map((e) => ({ "queue": e.queueName, "open": e.totAttendance }))

					console.log('userQueueInAttendance: ', userQueueInAttendance)

					let msg = '\nAVISO \n\nO atendente possui atendimento(s) em aberto na(s) fila(s) abaixo: \n\n'

					userQueueInAttendance.forEach((e) => {
						msg += `Fila: ${e.queue}\nAberto: ${e.open}\n\n`
					})

					msg += 'Para remover o atendente da(s) fila(s) acima é necessário que o mesmo encerre os atendimentos aberto(s) nessas filas.\n'

					alert(msg)

				}


			} else {
				await api.post("/users", userData)
			}
			toast.success(i18n.t("userModal.success"))
		} catch (err) {
			toastError(err)
		}
		handleClose()
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values)
							actions.setSubmitting(false)
						}, 400)
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((e) => !e)}
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
									/>
								</div>
								<Field
									as={TextField}
									label='Login'
									name="email"
									error={touched.email && Boolean(errors.email)}
									helperText={touched.email && errors.email}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<div className={classes.multFieldLine}> 
									<label style={{display: 'flex', alignItems:'center'}}>
										Transferir para outras filas
										<Switch
										name= 'transferToOtherQueues'
										checked={checked}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
										/>
									</label>
									
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.profile")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required
													>
														<MenuItem value="supervisor">Supervisor</MenuItem>
														<MenuItem value="admin">Admin</MenuItem>
														<MenuItem value="user">User</MenuItem>
													</Field>
												</>
											)}
										/>
									</FormControl>
								</div>

								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>

								<div style={{ marginTop: 6 }}>
									<FormControl fullWidth margin="dense" variant="outlined">
										<InputLabel id="0">{"Cargo"}</InputLabel>
										<Select
											labelId="0"
											value={selectedPosition}
											onChange={(e) => {
												const selectedValue = e.target.value

												if (selectedValue === '')
													setSelectedPosition('')
												else
													setSelectedPosition(selectedValue)
											}}
											label={"Cargo"}
										>
											<MenuItem value={''}>&nbsp;</MenuItem>
											{positions.map(({ id, name }) => (
												<MenuItem
													key={id}
													value={id}
												>{name}</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>  

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	)
}

export default UserModal
