import React, { useState, createContext } from "react";
   
const SearchTicketContext = createContext(); 


const SearchTicketProvider = ({ children }) => {

    const [searchTicket, setSearchTicket] = useState(0);
  
    return (
        <SearchTicketContext.Provider value={{ searchTicket, setSearchTicket }}>
            {children}
        </SearchTicketContext.Provider>
    );
};

export { SearchTicketContext, SearchTicketProvider };