import React, { useState, useEffect, useRef, useContext } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import openSocket from 'socket.io-client'


import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { i18n } from "../../translate/i18n"
import QueueSelect from '../QueueSelect'
import { AuthContext } from '../../context/Auth/AuthContext'

import api from "../../services/api"
import toastError from "../../errors/toastError"

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}))

const StatusChatEndSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
})

const StatusChatEndModal = ({
  open,
  onClose,
  statusChatEndId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles()
  const isMounted = useRef(true)

  const initialState = {
    name: "",
    farewellMessage: "",
    isDefault: false,
  }

  const [statusChatEnd, setStatusChatEnd] = useState(initialState)
  const { user, setting, getSettingValue } = useContext(AuthContext)
  const [settings, setSettings] = useState(setting)

  // console.log('USER: ', JSON.stringify(user, null, 6))

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setSettings(setting)
  }, [setting])


  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL)

    socket.on('settings', (data) => {
      if (data.action === 'update') {
        setSettings((prevState) => {
          const aux = [...prevState]
          const settingIndex = aux.findIndex((s) => s.key === data.setting.key)
          aux[settingIndex].value = data.setting.value
          return aux
        })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setStatusChatEnd((prevState) => {
          return { ...prevState, ...initialValues }
        })
      }

      if (!statusChatEndId) return

      try {
        const { data } = await api.get(`/statusChatEnd/${statusChatEndId}`)
        if (isMounted.current) {
          setStatusChatEnd(data)
        }
      } catch (err) {
        toastError(err)
      }
    }

    fetchQuickAnswer()
  }, [statusChatEndId, open, initialValues])

  const handleClose = () => {
    onClose()
    setStatusChatEnd(initialState)
  }

  const handleSaveStatusChatEnd = (values) => {

    (async () => {
      try {

        if (statusChatEndId) {
          await api.put(`/statusChatEnd/${statusChatEndId}`, values)
          handleClose()
          toast.success("Status de encerramento editado com sucesso")

        } else {
          const { data } = await api.post("/statusChatEnd", values)
          if (onSave) {
            onSave(data)
          }
          handleClose()
          toast.success("Status de encerramento criado com sucesso")

        }
      } catch (err) {
        toastError(err)
      }
    })()


  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {statusChatEndId
            ? `Editar Status de encerramento`
            : `Adicionar Status de encerramento`}
        </DialogTitle>
        <Formik
          initialValues={statusChatEnd}
          enableReinitialize={true}
          validationSchema={StatusChatEndSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveStatusChatEnd(values)
              actions.setSubmitting(false)
            }, 400)
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={"Name"}
                    name="name"
                    autoFocus
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={"Mensagem de despedida"}
                    name="farewellMessage"
                    error={touched.farewellMessage && Boolean(errors.farewellMessage)}
                    helperText={touched.farewellMessage && errors.farewellMessage}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {statusChatEndId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

export default StatusChatEndModal
