
import { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Modal from '../Modal'
import { render } from '@testing-library/react';

import chat from '@material-ui/icons/Chat';

// import Button from "@material-ui/core/Button"; 

import React from 'react';

import { i18n } from '../../../translate/i18n';

const MTable = (props) => {

  const tableRef = React.useRef(); 
 
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }; 
   
  const [selectedRow, setSelectedRow] = useState(null); 
 
  const dataLoad = props.data.map(({ user, ...others }) => ({ ...others, 'user': user ? user : { name: 'Aguardando atendente', email: '' } }));

  const columnsLoad = props.columns.map((column) => { return { ...column } });
 

  // useEffect(() => {

  //   

  // }, [selectedRow]);


  useEffect(() => {

    if (!tableRef.current) return 

    const element = tableRef.current.tableContainerDiv.current;

    element.addEventListener('scroll', props.handleScroll);

    return () => {
      element.removeEventListener('scroll', props.handleScroll);
    };

  }, [props]);






  return (

    <>
      {/* <Button onClick={handleTest}>Toggle</Button> */}

      {/* <CircularProgress /> */}

      <MaterialTable
        title={props.table_title}
        columns={columnsLoad}
        data={dataLoad}
        maxWidth={true}

        tableRef={tableRef}   

        localization={{ header: { actions: props.hasChild ? 'Ticket' : null },}}

        onRowClick={(evt, selectedRow) => {

          if (props.removeClickRow) {
            return
          }
 
          setSelectedRow(selectedRow.tableData.id)

          if (props.hasChild) {
            render(<Modal data={selectedRow.messages}
              hasChild={false}
              modal_header={i18n.t("reports.listTitles.title2_1")}
              user={selectedRow.user.name}
              clientContactNumber={selectedRow.contact.number} />)
          }
 

          //evt.stopPropagation() 
        }
        }


        actions={[
          (rowData) => {  

            if(props.hasChild){
              return {
                icon: chat,
                tooltip: `Ticket id ${rowData.id}`,
                disable: false,
                onClick: (event, rowData) => {
   
                  openInNewTab(`/tickets/${rowData.id}`) 
  
                }
              }
            } 
          }
        ]}

        options={{
          search: true,
          selection: false,
          paging: false,
          padding: 'dense',

          exportButton: props.hasChild ? true : null,
          exportAllData: true,

          sorting: true ? props.hasChild : false,
          // loadingType: 'circular',
          searchFieldStyle: {
            width: 300,
          },

          pageSize: 20,

          headerStyle: {
            position: "sticky",
            top: "0"
          },

          maxBodyHeight: "400px",
          // minBodyHeight: "85vh", //FIXME to calculate dynamic height, needed for correct scroll position identification
          // maxBodyHeight: "85vh",



          rowStyle: rowData => ({
            fontSize: 12,
            backgroundColor: selectedRow === rowData.tableData.id ? '#ec5114' : '#FFF'
          })
        }}
      />
    </>


  );

};


export default React.memo(MTable)