import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'



const SelectTextFields = (props) => {
  const [currency, setCurrency] = useState(props.textBoxFieldSelected ? props.textBoxFieldSelected : '0')
  if (!props.textBoxFieldSelected) {
    props.currencies.push({ 'value': 0, 'label': '' })
  }

  if(props.textBoxFieldSelected === 'All'){
    const already = props.currencies.findIndex(obj => obj.value === 'All');
    if (already === -1) {
    props.currencies.push({ 'value': 'All', 'label': 'All' })
    }
  }

  useEffect(() => {

    props.func(currency)

  }, [currency, props.textBoxFieldSelected])

  const handleChange = (event) => {

    setCurrency(event.target.value)

  }

  return (

    <Box
      component="form"
      sx={{
        "&:hover": {
          "&& fieldset": {
            border: "1px solid black"
          }
        },

        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, },
      }
      }
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-select-currency-native"
        margin="dense"
        size="small"
        select
        label={props.header}
        value={currency}
        onChange={handleChange}
        SelectProps={{
          native: true,
        }}
      // helperText="Please select your currency"
      >

        {props.currencies.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}

      </TextField>


    </Box>


  )
}

export default SelectTextFields










/*

return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-select-currency"
          select
          label="Select"
          value={currency}
          onChange={handleChange}
          helperText="Please select your currency"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency-native"
          select
          label="Native select"
          value={currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          helperText="Please select your currency"
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
      <div>
        <TextField
          id="filled-select-currency"
          select
          label="Select"
          value={currency}
          onChange={handleChange}
          helperText="Please select your currency"
          variant="filled"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="filled-select-currency-native"
          select
          label="Native select"
          value={currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          helperText="Please select your currency"
          variant="filled"
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
      <div>
        <TextField
          id="standard-select-currency"
          select
          label="Select"
          value={currency}
          onChange={handleChange}
          helperText="Please select your currency"
          variant="standard"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="standard-select-currency-native"
          select
          label="Native select"
          value={currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          helperText="Please select your currency"
          variant="standard"
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
    </Box>
  );

*/
