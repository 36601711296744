import React, { useState, useEffect, useContext, } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field,  } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import { AuthContext } from '../../context/Auth/AuthContext'
 

import apiBroker from '../../services/apiBroker'

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
 
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext"

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,  
} from '@material-ui/core'
 
import { i18n } from '../../translate/i18n'
import toastError from '../../errors/toastError' 

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const SessionSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),

    secondStart: Yup.number()
        .required('Min time is required')
        .min(3, 'Min time must be 3')
        .max(3600, 'Min must be less than 3600'),

    secondEnd: Yup.number()
        .required('Max time is required')
        .min(3, 'Min time must be 3')
        .max(3600, 'Max must be less than 3600')
        .test('higher-than-lower', 'Tempo final deve ser maior que tempo inicio!', function (
            secondEnd
        ) {
            const secondStart = this.resolve(Yup.ref('secondStart'))
            return secondStart === undefined || secondEnd === undefined || secondEnd > secondStart
        }),

})

const CampaignModal = ({ open, onClose, campaignId, dispatch }) => {
    const classes = useStyles()
    const initialState = {
        name: '',
        message: '',
        status: 'pending',
        whatsapp_sender: '',
        csv_original_file_name: '',
        secondStart: '3',
        secondEnd: '15',
        textToSeconds: true,
    }

    const { user } = useContext(AuthContext)

    const { whatsApps } = useContext(WhatsAppsContext) 

    const [campaign, setCampaign] = useState(initialState)
    // const [selectedQueueIds, setSelectedQueueIds] = useState([])
    const [file, setFile] = useState()

    const [selectedNumber, setSelectedNumber] = useState('')
 

    useEffect(() => {
        const fetchSession = async () => {
            if (!campaignId) return

            try {

                const { data } = await apiBroker.get('/campaign', {
                    params: {
                        adminId: user.id,
                        baseURL: process.env.REACT_APP_BACKEND_URL_PRIVATE,
                        identifier: 'campaign',
                        id: campaignId
                    }
                })

                setCampaign(data.campaign)
                setSelectedNumber(data.campaign.whatsapp_sender)

            } catch (err) {
                toastError(err)
            }
        }
        fetchSession()
    }, [campaignId, user.id])

    const handleSaveCampaign = async (values) => {
        let response = null

        const formData = new FormData()
        formData.append('adminId', user.id)
        formData.append('baseURL', process.env.REACT_APP_BACKEND_URL_PRIVATE)
        formData.append('frontURL', process.env.REACT_APP_FRONTEND_URL)
        formData.append('identifier', 'campaign')
        formData.append('file', file)
        formData.append('name', values.name)
        formData.append('whatsapp_sender', selectedNumber)
        formData.append('secondStart', values.secondStart)
        formData.append('secondEnd', values.secondEnd)
        formData.append('textToSeconds', values.textToSeconds)
        formData.append('message', values.message)
        formData.append('csv_original_file_name', file?.name)


        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }

        try {
            if (campaignId) {

                response = await apiBroker.patch(`/campaign/${campaignId}`,
                    formData,
                    config
                )
                toast.success('Campanha atualizada com sucesso!')

            } else {

                response = await apiBroker.post('/campaign',
                    formData,
                    config
                )
                toast.success('Campanha criada com sucesso!')
            }

            dispatch({ type: "UPDATE_CAMPAIGNS", payload: response.data.campaign })

            handleClose()

        } catch (err) {
            toastError(err)
        }
    }

    const handleClose = () => {
        onClose()
        setCampaign(initialState)
        setSelectedNumber('')
        setFile(null)
    }

    async function handleChange(event) {
        try {
            if (event.target.files[0].size > 1024 * 1024 * 4) {
                alert('Arquivo não pode ser maior que 4 MB!')
                return
            }
 
            setFile(event.target.files[0])

        } catch (err) {
            toastError(err)
        }
    }
 
 
    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    {campaignId ? 'Editar campanha' : 'Adicionar campanha'}
                </DialogTitle>
                <Formik
                    initialValues={campaign}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveCampaign(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (

                        <Form>
                            <DialogContent dividers>
                                <div>
                                    <div className={classes.multFieldLine}>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('whatsappModal.form.name')}
                                            autoFocus
                                            name="name"
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            variant="outlined"
                                            margin="dense" 
                                            className={classes.textField}
                                        />

                                        <Select
                                            value={selectedNumber}
                                            onChange={(e) => setSelectedNumber(e.target.value)}
                                            label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
                                            required
                                        >
                                            <MenuItem style={{ background: "white", }} value={''}>&nbsp;</MenuItem>
                                            {whatsApps.map((whatsapp) => (
                                                <MenuItem
                                                    key={whatsapp.id}
                                                    value={whatsapp.number} 
                                                >{whatsapp.number}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    <div className={classes.multFieldLine}>

                                    </div>
                                </div>

                                <div>
                                    <Field
                                        as={TextField}
                                        label="Mensagem"
                                        type="message"
                                        multiline
                                        rows={5}
                                        fullWidth
                                        name="message"
                                        error={touched.message && Boolean(errors.message)}
                                        helperText={touched.message && errors.message}
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                    }}
                                >
                                    <input
                                        type="file"
                                        accept=".csv"
                                        style={{ display: 'none' }}
                                        onChange={handleChange}
                                        id="contained-button-file"
                                    />

                                    <label htmlFor="contained-button-file">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                        >
                                            CSV UPLOAD
                                        </Button>
                                    </label>

                                    <h3>{file?.name || campaign?.csv_original_file_name}</h3>
                                </div>

                                <div className={classes.multFieldLine} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Field
                                        as={TextField}
                                        label='Inicio em segundos'
                                        autoFocus
                                        name="secondStart"
                                        error={touched.secondStart && Boolean(errors.secondStart)}
                                        helperText={touched.secondStart && errors.secondStart}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                    />

                                    <Field
                                        as={TextField}
                                        label='Fim em segundos'
                                        autoFocus
                                        name="secondEnd"
                                        error={touched.secondEnd && Boolean(errors.secondEnd)}
                                        helperText={touched.secondEnd && errors.secondEnd}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                    />

                                    <label>
                                        <Field type="checkbox" name="textToSeconds"/>
                                        Tamanho do texto para segundos
                                    </label>
 
                                </div>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t('whatsappModal.buttons.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {campaignId
                                        ? i18n.t('whatsappModal.buttons.okEdit')
                                        : i18n.t('whatsappModal.buttons.okAdd')}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default React.memo(CampaignModal)
