import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions'; 
 
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
 
//import DataGridTable from '../Table';
import MTable from "../MTable";   

import { i18n } from '../../../translate/i18n';

import ExportCSV from '../ExportCSV'
//import { margin } from '@mui/system';

let columns = [
  { 
    title: `${i18n.t("reports.listColumns.column2_1")}`, 
    field: 'fromMe', 
    
  },
  
  {
    title: `${i18n.t("reports.listColumns.column0_8")}`, 
    field: 'body', 
    //cellStyle: {whiteSpace: 'nowrap'},
  },

  { title: `${i18n.t("reports.listColumns.column1_7")}`, field: 'createdAt' }

  /*cellStyle: {
    backgroundColor: '#039be5',
    color: '#FFF'
  },
  headerStyle: {
    backgroundColor: '#039be5',
    fontSize: 12
  }*/

]

  
 

const Modal = (props) => {
  const [open, setOpen] = React.useState(true);
  const [scroll, /*setScroll*/] = React.useState('paper');

 

  const dataChat = props.data.map((dt) => { 
    return {
      'fromMe': dt.fromMe  ? 'Atendente' : 'Cliente', 
      'body': dt.body,
      'createdAt': dt.createdAt
    }
  }); 
   
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
  
     
      <Dialog 
        open={open}
        onClose={handleClose} 
        fullWidth={true}
        maxWidth={'true'}  
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >   

        <DialogTitle id="scroll-dialog-title">{props.modal_header}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          </DialogContentText>

         
            <MTable data={dataChat} columns={columns} table_title={''} hasChild={false}/>   
         
        </DialogContent>
        
 
        <DialogActions>     
          <div style={{marginRight:'50px'}}>
            <ExportCSV user={props.user} 
            clientContactNumber={props.clientContactNumber}
            data={dataChat} 
            columns={columns}/> 
          </div>
          <Button onClick={handleClose}>Ok</Button>  
        </DialogActions>
      </Dialog>  
     
  );
}

export default Modal